<template>
    <!-- <tablebox> -->
        <!-- <div class="companyInfo dp-f ai-c subjectitem">
            <div class="imgbox mr-14 dp-fc">
                <div class="imgbox2 dp-fc">
                    <template v-if="userInfo.sysCompany">
                    <img class="w-40 h-40 br20"
                        v-if="userInfo.sysCompany.signInformationDefinition && userInfo.sysCompany.signInformationDefinition.logoUrl"
                        :src="userInfo.sysCompany.signInformationDefinition.logoUrl"
                        />
                    <img class="w-40 h-40 br20" :src="require('@/assets/img/general/enterprise.png')" v-else />
                    </template>
                    <img class="w-40 h-40 br20" :src="require('@/assets/img/general/user.png')" v-else />
                </div>
            </div>
            <div>
                <div class="fs-20 fw-b mb-10 dp-f">
                    <div class="mr-20">{{ userInfo.sysCompany ? userInfo.sysCompany.companyName : '个人账户' }}</div>
                    <template v-if="userInfo.sysCompany">
                    <realname is="true" CSStype="3" width="74" height="26" IMGtype="2" type="2"
                        :is="userInfo.sysCompany.isauthentication == 1" />
                    </template>
                </div>
                <div class="fs-14">{{ userInfo.chnname }} {{ userInfo.phone }}</div>
            </div>
        </div> -->
        <statistics class="mb-12"></statistics>
        <!-- <choosebuttons @Emit="butemit"></choosebuttons> -->
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="购买记录" name="recharge">
                <recharge />
            </el-tab-pane>
            <el-tab-pane label="使用记录" name="consum">
                <consumption />
            </el-tab-pane>
        </el-tabs>
    <!-- </tablebox> -->
</template>
<script setup>
import { reactive,ref,unref, computed } from 'vue'
import statistics from "./components/statistics.vue"
import choosebuttons from "@/components/button/choose.vue"
import recharge from "./components/center/recharge.vue"//充值记录
import consumption from "./components/center/consumption.vue"//消费记录
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const state = reactive({
    butIndex:0,//按钮选中下标
})
// 按钮返回
const butemit=((el)=>{
    state.butIndex=el
    // console.log('按钮返回',el)
})
const activeName = ref("recharge")
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

</style>