<template>
  <div class="w100 bg-fff pl-20 pr-20 pt-20 pb-20">
    <div class="w100 dp-f fw-w mb-24">
      <div class="input_box w-180">
        <el-input class="input" v-model="state.searchKey.consumeItem" :maxlength="config.inputMaxlength"
          show-word-limit placeholder="请输入合同主题">
        </el-input>
      </div>
      <div class="input_box w-300">
        <el-date-picker class="input" v-model="state.searchKey.consumeTime" type="datetimerange" range-separator="→"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss" />
      </div>
      <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="getdataList()"></oabutton>
    </div>
    <el-table :data="state.Notice" class="mb-20 table"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
      row-key="id" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner">
      <el-table-column label="" width="20" />
      <el-table-column :align="'center'" type="index" label="序号" width="80" />
      <!-- <el-table-column :align="'center'" prop="companyName" label="企业名称" /> -->
      <el-table-column :align="'center'" prop="consumeItem" label="合同主题" />
      <el-table-column :align="'center'" prop="consumeTime" label="使用时间" />
      <el-table-column :align="'center'" prop="consumeNumber" label="使用额度" />
    </el-table>
    <el-pagination class="dp-f jc-c" @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
      :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const state = reactive({
  searchKey: {
    consumeItem: '',//合同主题
    consumeTime: null,//使用时间
  },//搜索绑定值
  Notice: [],//表格数据
})
// 列表调取 页数
const getdataList = ((val=1) => {
  state.multipleSelection = []
  currentPage.value = val;
  loading.value = true
  queryResourceTable('/admin/account/selectConsume', qs.stringify({
    page: val,
    consumeTime:JSON.stringify(state.searchKey.consumeTime),
    consumeItem:state.searchKey.consumeItem,
  })).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdataList(res.data.current)
      return
    }
    // res.data.records.forEach(item => {
    // });
    totalPage.value = res.data.total;
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
})
getdataList()
const handleCurrentChange=((val)=>{
  getdataList(val)
})
</script>
<style lang="scss" scoped >
.table {
  min-height: 350px;
}
</style>